.metaworld {
  background-color: #2f324d;
}

.info-details {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  text-align: justify;
}
.img-price {
  /* border-radius: 20px;
  border: 5px solid black; */
}
