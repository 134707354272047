.footer {
  height: auto;
  background: #2f324d;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.icon-btn {
  font-size: 25px;
  color: white;
  cursor: pointer;
  transition: 0.5s;
}
.icon-btn:hover {
  transform: scale(1.1);
}
.copy-right {
  font-size: 16px;
  color: white;
}
/* .wave1-footer {
	animation: animate 12s linear infinite;
	z-index: 1000;
	opacity: 1;
	animation-delay: 0s;
	top: 0;
} */
/* .wave2-footer {
	animation: animate2 15s linear infinite;
	z-index: 999;
	opacity: 0.5;
	animation-delay: -05s;
	top: 10px;
}
.wave3-footer {
	animation: animate 10s linear infinite;
	z-index: 998;
	opacity: 0.2;
	animation-delay: -2s;
	top: 15;
}
.wave4-footer {
	animation: animate2 5s linear infinite;
	z-index: 997;
	opacity: 0.7;
	animation-delay: -5s;
	top: 20px;
} */
/* .margin-bottom-footer {
  margin-top: 3rem;
} */
@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}
@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}
