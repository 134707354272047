.scroll {
  background-color: #18181aab;
}
.withoutScroll {
  background-color: #18181a;
}
.button {
  background: linear-gradient(
    76.91deg,
    #ffffff -125.56%,
    rgb(168, 166, 166) 116.11%
  );
  border: none;
  padding: 0.72rem 1.5rem;
  color: #161111;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 19px;
  transition: 1s;
}
.button:hover {
  background: linear-gradient(
    76.91deg,
    #d8f1ff -125.56%,
    rgb(168, 166, 166) 116.11%
  );
  border: none;
  padding: 0.72rem 1.5rem;
  color: #161111;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 19px;
  transition: 1s;
}
.navbar {
  padding: 0px !important;
}
/* Remove border from toggler */
.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

/* Lines of the Toggler */
.toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #ffffff;

  display: block;
  transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar {
  margin: 5px auto;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
  background-color: #ffffff;
}

.nav-link {
  color: white;
  font-weight: bold !important;
  font-size: 19px;
  text-transform: uppercase;
}
a:hover {
  color: #ffe0e0 !important;
}
.nav-link:focus,
.nav-link:hover {
  color: #8feba0 !important;
}
