body {
  padding: 0;
  margin: 0;
  /* font-family: "Righteous", cursive; */
  /* font-family: "Permanent Marker", cursive; */
  /* font-family: "Rubik Glitch", cursive; */
  font-family: "Audiowide", cursive;
  /* font-family: "Racing Sans One", cursive; */
  /* font-family: "Josefin Sans", sans-serif; */
  /* background-repeat: no-repeat; */
  background-color: #2f324d;
  /* background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.773),
      rgba(255, 255, 255, 0.856)
    ), */
  /* url("../src/Component/Image/Web_bg/Web_bg_trans_v2.png"); */
  /* height: 100vh; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}

.light-background {
  background-color: #2f324d;
}

.subtitle-black {
  font-size: 19px;
  font-weight: 600;
  color: #0f0f0f;
  text-align: justify;
}
.title-black {
  font-size: 35px;
  font-weight: 700;
  color: #0f0f0f;
  text-transform: uppercase;
}
.subtitle-white {
  font-size: 19px;
  font-weight: 600;
  color: #fffdfd;
  text-align: justify;
}

.link {
  font-size: 18px;
  font-weight: 500;
  color: #fffdfd;
}
.title-white {
  font-size: 35px;
  font-weight: 700;
  /* color: #ffffff; */
  color: #8feba0;

  text-transform: uppercase;
}
.button-white {
  background: linear-gradient(
    76.91deg,
    #ffffff -125.56%,
    rgb(255, 255, 255) 116.11%
  );
  border: none;
  padding: 0.72rem 1.5rem;
  color: #0f0f0f;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 20px;
  transition: 1s;
}
.button-dark {
  background: #18181a;
  border: none;
  padding: 0.72rem 1.5rem;
  color: #ffffff;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 20px;
  transition: 0.3s;
}
.button-dark:hover {
  background-color: #8feba0;
  /* opacity: 0.5; */
}

.width-part {
  width: 90%;
}
@media (max-width: 700px) {
  .title-black,
  .title-white {
    font-size: 28px;
  }
  .subtitle-black,
  .subtitle-white {
    font-size: 14px;
    text-overflow: clip;
  }
}

@media (max-width: 990px) {
  .width-part {
    width: 100%;
  }
}
