.collection-part {
  background-color: #2f324d;
}

.collection {
  border-radius: 50%;

  border: 3px solid black;
}
@media (max-width: 600px) {
  .padding-bottom {
    padding-bottom: 3rem !important;
  }
}
