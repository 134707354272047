.power {
  background-color: #2f324d;
  /* background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.773),
      rgba(255, 255, 255, 0.856)
    ),
    url("../Image/Web_bg/Web_bg_trans.png");
  background-size: cover;
  background-repeat: no-repeat; */
}
.size {
  width: 350px;
  height: auto;
  margin: 0 auto;
}
