.main {
	/* background: #0f0f0f; */
	background: url("../Image/Header/AA_header.gif") no-repeat;
	/* background: #0f0f0f; */
	background-size: cover;
	/* background-size: contain; */
	background-repeat: no-repeat;
	background-position: center;
	/* height: auto; */
	/* position: relative; */
	/* overflow: hidden; */
	width: 100%;
	height: 90vh;
}

@media (max-width: 990px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 39vh;
	}
}
@media (max-width: 912px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 37vh;
	}
}
@media (max-width: 820px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 39vh;
	}
}
@media (max-width: 768px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 42vh;
	}
}
@media (max-width: 550px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 26vh;
	}
}
@media (max-width: 540px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 42vh;
	}
}
@media (max-width: 414px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 26vh;
	}
}
@media (max-width: 412px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 25vh;
	}
}
@media (max-width: 393px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 26vh;
	}
}
@media (max-width: 375px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 32vh;
	}
}
@media (max-width: 360px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 27vh;
	}
}
@media (max-width: 280px) {
	.main {
		background-position: center;
		background-size: cover;
		height: 24vh;
	}
}
