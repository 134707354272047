.team {
  background-color: #2f324d;
}
.img-team {
  width: 200px;
  height: 200px;
  background-color: rgb(216, 71, 71);
  border-radius: 50%;
  border: 3px solid black;
  transition: 0.3s;
}
.img-team:hover {
  transform: scale(1.1);
}
.team-title {
  font-size: 20px;
  font-weight: 800;
  color: #ffffff;
}
.team-deatils {
  font-size: 19px;
  font-weight: 600;
  color: #ffffff;
}
.team-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}
@media (max-width: 990px) {
  .team-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 700px) {
  .team-grid {
    grid-template-columns: 1fr;
  }
  .team-title {
    font-size: 18px;
  }
  .team-deatils {
    font-size: 17px;
  }
}
