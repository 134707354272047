.coinbase {
  /* background: url("../Image/Web_bg/Web_bg_trans_v2.png"); */
  background-color: rgb(255, 255, 255);
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.dark {
  background-color: #0f0f0f;
}
.left-width {
  width: 50%;
}
@media (max-width: 990px) {
  .left-width {
    width: 100%;
  }
}
